* {
  font-family: "SF Pro Display", sans-serif;
}

.bgblack {
  background-color: black;
}
.textgrey {
  /* color: #616161; */
  color: #b5b5b5;
}
.bg3 {
  background-color: #101010;
}
.bg4 {
  background-color: #1f1d1b;
}
.text_purple {
  color: #9607b1;
}
.text_blue {
  color: #2d62ed;
}
.fontsize13 {
  font-size: 13px;
}
.fontsize14 {
  font-size: 14px;
}
.fontsize12 {
  font-size: 12px;
}
.textgrey2 {
  color: #8d8b8b;
}
.textgrey3 {
  color: #616161;
}
.textgrey4 {
  color: #b7b7b7;
}
.textblack{
  color: black;
}

.tech-p {
  font-size: 24px;
}
.fontsize18{
  font-size: 18px;
}
.bg_green {
  background-color: #6adc26;
}
.text_green {
  color: #6adc26;
}
.bglightgrey {
  background-color: #f2f3f6;
}
.bglightgrey {
  background-color: #f2f3f6;
}

.cursor {
  cursor: pointer;
}
.textgreen {
  color: #6adc26;
}
.fontsize13 {
  font-size: 13px;
}

.bglightblack{
  background-color: #1F1D1B;
}

.navbar_list li a {
  color: #c6c3c3;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
  font-size: 14px;
}

.navbar-nav a:hover {
  color: #6adc26;
}

.navbar_list li a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 3px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #6adc26;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navbar_list li a:hover:after {
  width: 100%;
  left: 0;
}
.textwhite_light{
  color: #c6c3c3;
}

.hovermenu_list a {
  text-transform: capitalize;
  text-decoration: none;
  color: #929292;
  font-size: 21px;
  line-height: 38px;
}
.hover_desc h1 {
  font-size: 60px;
}
.hovermenu_list a:hover {
  color: #6adc26;
}
.hovermenu_list2 a {
  text-transform: capitalize;
  text-decoration: none;
  color: #6adc26;
  font-size: 21px;
  line-height: 38px;
}
.hovermenu_list2 a:hover {
  color: #929292;
}
.container > div .bg-video {
  width: 100%;
  height: 90vh;
  object-fit: cover;
 
}
.Revolutionary_text {
  margin-top: -5%;
}
.eye-girl-text {
  background-color: #cdbfbc;
}
.container >div  .video_text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: black;
  color: #fff;
  font-size: 23vw; /* Use vw (viewport width) for responsive font size */
  mix-blend-mode: multiply;
  z-index: 0;
}
.meet_text {
  position: absolute;
  font-size: 1.7vw;
  margin-left: 62%;
  bottom: 18%;
  /* background-color: #6adc26; */
}

.welcome_text h1 {
  font-size: 4.3vw;
}
.experience_text h1 {
  font-size: 6.3vw;
}
.experience_desc {
  font-size: 1.3vw;
}
.body_mind_text{
  margin-top: -1%;
}
.welcome_div h4{
  font-size: 1.6vw;
}
.welcome_div p{
  font-size: 1.1vw;
}

.card_img {
  position: absolute;
  top: 0;
  /* height: 550px; */
  left: -50%;
  z-index:0;
  transition: transform 0.3s ease-in-out;
  /* transform: rotateY(0deg); */
  /* transform: skewX(-10deg) skewY(-15deg) rotateY(55deg); */

}


.card_img2 {
  z-index: 0;
  height: 550px;
  transition: transform 0.3s ease-in-out;
  /* transform: skewX(-10deg) skewY(-25deg) rotateY(55deg); */
 
}

.card_animation:hover{
  animation: down 5s linear infinite;
  /* z-index: 1000 !important; */
}
@keyframes down {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(30deg);
  }
  
  100% {
    transform: rotateY(0deg);
  }
}

 .card_img:hover {

  transform: skewX(0deg) skewY(deg)
} 
.card_img2:hover {
  transform: translateX(50%);
  z-index: 1000 !important;
  transition: transform 0.3s ease-in-out;
} 


/* .card_1:hover .card_img2 {
  transform: translateX(10%);
  transform: rotateY(30deg);
  z-index: 0;

} */

.form__group {
  position: relative;
  padding: 20px 0 0;
  margin-top: 10px;
  width: 85%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ccc;
  outline: 0;
  color: black;
  padding: 11px 0px;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  /* font-size: 1.3rem; */
  cursor: text;
  top: 30px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #ccc; /* Replace $gray with the desired color value */
}

.form__field:focus {
  padding-bottom: 10px;
  font-weight: 100;
  border-width: 3px;
  border-image: linear-gradient(to right, #6adc26, #6adc26);
  border-image-slice: 1;
}

/* Reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.submit_btn {
  width: 220px;
  padding: 7px;
}
.order_div p {
  margin: 0;
}

.comp_main {
  color: #8d8b8b;
  background-color: #101010;
}

.comp_benefits {
  font-size: 1.3vw;
  position: absolute;
  bottom: 47%;
  left: 41%;
  z-index: 1;
  color: #b9abb1;
}

.comp_benefits h2 {
  font-size: 2vw;
}

.compmobbene {
  bottom: 45%;
  left: 36%;
  position: absolute;
  font-size: 3vw;
  z-index: 1;
  color: #b9abb1;
}

.compmobbene h2 {
  font-size: 4vw;
}

.compcontent {
  max-width: 60%;
}

.compcontent p {
  font-size: 22px;
}

.mobbenif {
  overflow: hidden !important;
  position: relative;
  /* max-width: 100%;
  max-height: 100%; */
}

.benefitmobimg {
  /* transition: transform 0.7s ease-in-out; */
  animation: rotate 100s linear infinite;
  /* max-width: 1100px; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/* .benefitmobimg:hover {
  transform: rotate(60deg);
} */

.benefitsimg {
  /* transition: transform 0.7s ease-in-out; */
  animation: rotate 100s linear infinite;
}

/* .benefitsimg:hover {
  transform: rotate(60deg);
} */


.greycircle {
  position: absolute;
  bottom: 27%;
  left: 31%;
  opacity: 1;
  width: 38%;
}

.greycircle1 {
  position: absolute;
  bottom: 23%;
  left: 23%;
  opacity: 1;
  width: 53%;
}

.mobsmiley {
  width: 60px;
  height: 60px;
}

.mobsmiley1 {
  z-index: 1;
  width: 65px;
  height: 63px;
  background-image: url("../../public/images/smile02.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.benitem:hover > .mobsmiley1 {
  background-image: url("../../public/images/smile01.png");
}
.benitem:hover > p {
  background-color: #6adc26;
  color: black;
  border-radius: 15px;
}

.benmobbtn1 {
  background-color: #1f1d1b;
  color: white;
  border-radius: 10px;
  border: 100px;
  font-size: 24px;
  width: 250px;
}

.benmobbtn2 {
  background-color: #2d62ed;
  color: white;
  border-radius: 10px;
  border: 100px;
  font-size: 18px;
  width: 250px;
}

.verline {
  height: 40px;
  width: 2px;
  background-color: #2d62ed;
}
.smiley1img {
  z-index: 1;
  width: 25%;
  background-image: url("../../public/images/smile02.png");
  background-repeat: no-repeat;
  background-size: contain;
}

/* .smiley1img:hover {
  background-image: url("../../public/images/smile01.png");
} */

/* .smiley1 > .smiley1img {
  background-image: url("../../public/images/smile01.png");
}
.smiley1 > p {
  background-color: #6adc26;
  color: black;
  border-radius: 30px;
} */

.smiley1 {
  position: absolute;
  bottom: 70%;
  right: 72%;
}

.smiley1 p {
  font-size: 1vw;
  border: 5px;
  border-radius: 30px;
  line-height: 19px;
}


.smiley2img {
  width: 37%;
  background-image: url("../../public/images/smile02.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.smiley2:hover > .smiley2img {
  background-image: url("../../public/images/smile01.png");
}
.smiley2:hover > p {
  background-color: #6adc26;
  color: black;
  border-radius: 30px;
}

.smiley-hover-effect > div {
  background-image: url("../../public/images/smile01.png");

}
.smiley-hover-effect > p {
  background-color: #6adc26;
  color: black;
  border-radius: 30px;

}

.smiley2 {
  position: absolute;
  bottom: 53%;
  right: 79%;
  z-index: 1;
}

.smiley2 p {
  font-size: 1vw;
  border: 5px;
  border-radius: 30px;
  line-height: 19px;
}

.smiley3img {
  z-index: 1;
  width: 30%;
  background-image: url("../../public/images/smile02.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.smiley3 {
  position: absolute;
  bottom: 34%;
  right: 78%;
  z-index: 1;
}

.smiley3 p {
  font-size: 1vw;
  border: 5px;
  border-radius: 30px;
  line-height: 19px;
}

.smiley3:hover > .smiley3img {
  background-image: url("../../public/images/smile01.png");
}
.smiley3:hover > p {
  background-color: #6adc26;
  color: black;
  border-radius: 30px;
}

.smiley4img {
  z-index: 1;
  width: 22%;
  background-image: url("../../public/images/smile02.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.smiley4 {
  position: absolute;
  bottom: 18%;
  right: 68%;
}

.smiley4 p {
  font-size: 1vw;
  border: 5px;
  border-radius: 30px;
  line-height: 19px;
}

.smiley4:hover > .smiley4img {
  background-image: url("../../public/images/smile01.png");
}
.smiley4:hover > p {
  background-color: #6adc26;
  color: black;
  border-radius: 30px;
}

.visitus {
  font-size: 12px;
}

.eclipse {
  position: absolute;
  bottom: 18%;
  left: 18%;
  width: 20%;
}

.tech-ben-con1 {
  position: absolute;
  bottom: 35%;
  left: 78%;
  color: #8d8b8b;
  width: 300px;
}

.tech-ben-con1 h6 {
  font-size: 1.5vw;
}

.tech-ben-con1 p {
  font-size: 1vw;
  color: #7de01f;
}

.benbtn1 {
  background-color: #1f1d1b;
  width: 100%;
  color: white;
  font-size: 1vw;
  border-radius: 10px;
  border: 100px;

}

.benbtn1:hover {
  background-color:#1f1d1b; 
  /* text-decoration: underline; */
  text-underline-offset: 3px;;
  color: #6adc26;
  
}
.benbtn2 {
  background-color: #2d62ed;
  width: 123px;
  color: white;
  font-size: 0.9vw;
  border-radius: 10px;
  border: 100px;
  height: 100%;
}
.benbtn2:hover {
  background-color: #6adc26;;
  width: 123px;
  color:black;
  font-weight: 600;
  font-size: 0.9vw;
  text-underline-offset: 3px;
  border-radius: 10px;
  border: 100px;
}

/* .pendamic_font{
  font-size: 1vw !important;
} */

.tech-card {
  background-color: #1f1d1b;
  height: 500px;
  width: 543px;
  border-radius: 15px;
}
.card_design {
  background-color: #1f1d1b;
  height: 460px;
  width: 500px;
  border-radius: 15px;
}

.tech-card p {
  font-size: 18px;
}

.techimg1 {
  width: 200px;
}

.techimg2 {
  width: 300px;
}
.techimg3 {
  width: 177px;
  top: 9%;
}

.tech-uses-p {
  font-size: 16px;
  color: #b5b5b5;
}

.techimg4 {
  width: 80%;
  border-radius: 15px;
}

.benefits-card {
  background-color: #1f1d1b;
  max-height: 75%;
  width: 80%;
  border-radius: 20px;
}

.tech-card-icon {
  width: 75px;
}

.benefits-content {
  width: 25%;
}

.benefits-content p {
  font-size: 11px;
  width: 125px;
}

.tech-video-text {
  position: absolute;
  top: 10%;
  left: 0;
  height: 60%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: black;
  color: #fff;
  font-weight: 600;
  font-size: 130px;
  /* Mix Blend Mode does the all magic */
  mix-blend-mode: multiply;
}

.tech-video {
  height: 100%;
  width: 100%;
}
.checkout_btn {
  background: black;
  color: white;
  padding: 3% 39%;
  border: 0;
}
.paymethod_div {
  background-color: #fcfbda;
  border: 1px solid #e6e2be;
  border-radius: 8px;
}

.form_field_2 {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ccc; /* Replace $gray with the desired color value */
  outline: 0;
  color: black; /* Replace $white with the desired color value */
  padding: 12px 0px;
  background: transparent;
  transition: border-color 0.2s;
}
.billing_label {
  position: absolute;
  cursor: text;
  top: 32%;
  left: 7%;
}
.nav_product {
  position: absolute;
  height: 108px;
  /* height: 100%; */
  width: 65px;
  right: 0;
  top: 0;
}

.count_div {
  border-radius: 50%;
  border: 0;
  height: 13px;
  width: 13px;
  font-size: 8px;
  background-color: #1f1d1b;
  color: white;
  align-items: center;
  z-index: 999;
}
.cart_count {
  z-index: 1000;
}
.count_icon {
  position: absolute;
  bottom: 50%;
  left: 50%;
}

.bubble1 {
  position: absolute;
  bottom: 65%;
  left: 10%;
  width: 25%;
}
.bubble2 {
  position: absolute;
  bottom: 80%;
  left: 50%;
  width: 20%;
  z-index: 1000px;
}
.bubble3 {
  position: absolute;
  bottom: 85%;
  left: 75%;
  width: 14%;
}
.bubble4 {
  position: absolute;
  bottom: 65%;
  left: 65%;
  width: 14%;
}

.tech-link {
  position: absolute;
  bottom: 10%;
  left: 34%;
  transition: left 0.3s ease-out;
}

.tech-link:hover {
  left: 38%;
}
.product_link {
  color: white;
  text-decoration: none;
}
.product_link:hover {
  color: white;
}
.input-qty {
  border: none;
  background-color: white;
}

.certificate {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.overlay {
  background: blue;
  width: 100%;
  top: 0;
  position: absolute;
  bottom: 0;
  opacity: 0.5;
}
.enquiry_logo {
  top: 10%;
  left: 32%;
}
.vh100 {
  height: 100vh;
}
.checkkk {
  z-index: -1;
}
.width75 {
  width: 75%;
}

.custom-accordion .accordion-item {
  background-color: #171727;
  border-radius: 15px;
  border: none !important;
  border-bottom: none !important;
  outline: none !important;
}

.custom-accordion .accordion-button:not(.collapsed) {
  background-color: #171727 !important;
  box-shadow: none;
  color: white;
  outline: none !important;
}
.custom-accordion .accordion-button {
  background-color: #171727 !important;
  box-shadow: none;
  color: white;
  outline: none !important;
}

.custom-accordion .accordion-button:focus {
  box-shadow: none;
  outline: none;
  background-color: #171727;
  color: white;
}
.accordion-button:not(.collapsed)::after {
  content: "";
  transform: rotate(0deg);
  color: white;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23ffffff"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.navbar {
  transition: opacity 0.3s ease;
  opacity: 1;
}

.navbar-hidden {
  opacity: 0;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-2%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.smooth {
  transition: opacity 0.3s ease;
}
body::-webkit-scrollbar {
  width: 5px;
  /* display: none;            */
}

body::-webkit-scrollbar-track {
  background: black;
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid #6adc26;
}

.accordion-button:not(.collapsed)::after {
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(0deg);
  color: #000;
}

.aboutus_div h2 {
  color: #929292;
  font-size: 27px;
  margin-top: 45px;
}

.aboutus_div p,
h4,
h5 {
  color: #929292;
}
.custom_padding {
  margin-top: 10%;
  margin-bottom: 11%;
}
.enquiry_img {
  height: 600px;
  object-fit: contain;
}
.custom_bottom {
  bottom: -8%;
}
.about_heading {
  font-size: 60px;
}
.about_desc {
  text-align: center;
}
.electron_text {
  font-size: 60px;
}

.zoom-in-zoom-out {
  transition: transform 0.3s ease-out;
}

.zoom-in-zoom-out:hover {
  transform: scale(0.9);
}

.image_effect:hover {
  transform: translateX(20px);
}
.text_hover {
  color: #c6c3c3;
}
.text_hover a {
  color: #c6c3c3;
}
.text_hover a:hover {
  color: #7de01f;
}

.certificate_heading{
  text-decoration: underline;
  text-decoration-color: #616161;
  text-underline-offset: 8px;
}
.health_font{
  font-size: 58px;
  line-height: 58px;
}
.girl_img{
  width: 150px;
}
.buy_amazon_btn:hover{
  background-color: #6adc26;
  color: white;

}
.media-img{
  height: 80px;
}
.marquee_display{
  display: block;
}
.blog_font{
  font-size: 1.3vw;
}
.blog_fon2{
  font-size: 1vw;
}

.aboutus_text h1{
  font-size: 70px;

}

.show{
  display: none;
}
.show2{
  display: block;
}
@media only screen and (max-width: 640px) {
  
  .show2{
    display: none;
  }
  .show{
    display: block;
  }
  .aboutus_text h1{
    font-size: 30px;
  
  }
  .blog_font{
    font-size: 4vw;
  }

  .blog_fon2{
    font-size: 3vw;
  }
  
  .marquee_display{
    display: none;
  }
  .girl_img{
    width:115px ;
  }
  .health_font{
    font-size: 35px;
    line-height: 40px;
  }
  .about_heading {
    font-size: 44px;
  }
  .about_desc {
    text-align: start;
  }
  .nav_product {
    position: absolute;
    height: 95px;
    /* height: 100%; */
    width: 50px;
    right: 0;
    top: 0;
  }

  .count_icon {
    position: absolute;
    bottom: 50%;
    left: 50%;
  }
  .experience_desc {
    font-size: 4.8vw;
    line-height: 26px;
  }
  
  .container >div .video_text {
    
    top: 0%;
    left: 0px;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 120px;
    mix-blend-mode: multiply;
    transform: scale(1.5);
  }
  .Revolutionary_text {
    font-size: 16px;
    margin-top: -1%;
  }
  .width75 {
    width: 100%;
  }
  .container >div .bg-video {
    width: 100%;
    height: 100vh;
  }
  .tech-video-text {
    position: absolute;
    top: 10%;
    left: 0px;
    height: 22%;
    width: 100%;
    display: grid;
    place-items: center;
    background-color: #000;
    color: #fff;
    font-size: 50px;
    mix-blend-mode: multiply;
  }
  .benefits-card {
    background-color: #1f1d1b;
    max-height: 75%;
    width: 100%;
    border-radius: 20px;
  }
  .techimg4 {
    width: 100% !important;
    border-radius: 15px;
  }
  .techimg3 {
    width: 175px;
    top: 6%;
  }

  .hover_desc h1 {
    font-size: 30px;
  }
  .hovermenu_list a {
    text-transform: capitalize;
    text-decoration: none;
    color: #929292;
    font-size: 18px;
    line-height: 30px;
  }
  .hovermenu_list2 a {
    text-transform: capitalize;
    text-decoration: none;
    color: #6adc26;
    font-size: 18px;
    line-height: 30px;
  }
  .hovermenu_list2 a:hover {
    color: #929292;
  }

  .display{
    display: none;
  }

  .welcome_div h4{
    font-size: 5vw;
  }
  .welcome_div p{
    font-size: 4vw;
  }
  .tech-p {
    font-size: 20px;
  }
  .tech-card {
    height: auto;
    width: 100% !important;
  }
  .card_design {
    height: auto;
    width: 100% !important;
  }
  .benefits-content {
    width: 50%;
  }
  .check {
    margin-left: 0%;
  }

  .benbtn2 {
    background-color: #2d62ed;
    width: 50px;
    color: white;
    font-size: 0.8vw;
    border-radius: 10px;
    border: 100px;
  }

  .compcontent {
    max-width: 100%;
  }

  .card_img {
    height: 500px;
    left: -40%;
  }

  .card_img2 {
    height: 350px;
    margin-left: 20%;
  }

 
  .tech-ben-con1 h6 {
    font-size: 2vw;
  }
  .tech-ben-con1 {
    width: 80px;
    bottom: 30%;
    left: 78%;
  }
  .health_container{
    transform: rotate(-90deg);
  }  
  .meet_text {
    bottom: 36%;
    font-size: 18px;
    left: 0%;
  }
  .hover_desc h1 {
    font-size: 30px;
  }

  .welcome_text h1 {
  display: none;
  }
  .experience_text h1 {
    display: none;

  }
  .welcome_mobile_text h1{
    font-size: 10vw;
    line-height: 35px;
  }

  .body_mind_text2{
    margin-top: 0%;
    font-size: 13vw;
    line-height: 3.3rem;
  }
  .card_img {
    height: 350px;
  }
  .form__group {
    position: relative;
    padding: 20px 0 0;
    margin-top: 10px;
    width: 100%;
  }
  .vh100 {
    height: 60vh;
  }

  .enquiry_img {
    height: 550px;
    object-fit: contain;
  }
  .custom_bottom {
    bottom: -7%;
  }
  .aboutus_div h2 {
    color: #929292;
    font-size: 21px;
    margin-top: 45px;
  }
  .aboutus_div h5 {
    font-size: 16px;
  }
  .electron_text {
    font-size: 35px;
  }
  .TechHoverImg {
    
  
    margin-left: 0px !important;
  
}.bgblack {
  background-color: #101010;
}
.mobile_hover_nav{
  height: 100vh;
}
}
.input-qty {
  border: none;
  background-color: white;
}




.TechHoverImg{

    width: 100%;
    overflow: hidden;
    margin-left: 12px;
    transition: transform 0.3s ease-in-out, margin-left 0.3s ease-in-out;


}
.Tech:hover  .TechHoverImg{
  margin-left: 0px !important;
}