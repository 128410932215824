.bg1 {
  background-color: #171727;
}

.img {
  width: 180px;
}

.qrimg {
  width: 100px;
}

.health {
  width: 600px;
}

.text1 {
  color: #b7b7b7;
  font-size: 1.5vw;
}

.text2 {
  color: #b7b7b7;
  font-size: 1.2vw;
}

.fot {
  color: #b7b7b7;
  font-size: 1vw;
}

.text1 :hover {
  color: #7de01f;
}

.toll {
  font-size: 1.2vw;
  color: #b7b7b7;

}

.scan {
  font-size: 1vw;
  color: #b7b7b7;
}

.icons img {
  width: 25px;
}

.last {
  background-color: black;
  font-size: 1vw;
}
.insta{
  font-size: 21px;
}
.navitems {
  text-decoration: none;
  color: #b7b7b7;
}
.navitems:hover{
  color: #7de01f;
}
.footer_weblink{
 
  text-decoration: none;

}
.footer_weblink:hover{
  color: #7de01f;

}

@media only screen and (max-width: 768px) {
  .text1 {
    font-size: 2.9vw;
  }
  .text2 {
    font-size: 2.8vw;
  }
  .fot {
    font-size: 15px;
  }
  .toll {
    font-size: 20px;
  }
  .scan {
    font-size: 3vw;
  }
  .qrimg {
    width: 60px;
  }
  .icons img {
    width: 18px;
  }
  .icons2 img {
    width: 25px;
  }
  .last {
    font-size: 2.8vw;
  }
  .insta{
    font-size: 19px;
  }
  .navitems_mobile {
    text-decoration: none;
    color: #b7b7b7;
    font-size: 20px;
    line-height: 26px;
  }
  .navitems_mobile:hover{
    color: #7de01f;
  }
  .footer_address{
    font-size: 17px;
  }
  .health_img{
    width: 350px;
  }
}
