.next {
  height: 130px;
  background-color: #2d62ed;
  width: 120px;
  border-radius: 15px;
}

.icon1 {
  width: 30px;
}

.carousel-content {
  color: white;
  background-color: #1f1d1b;
  max-height: 150px;
  border-radius: 15px;
  color: #676363;
  font-size: 1.3vw;
  width: 260px;
  line-height: 24px;
}

.controls {
  bottom: 14%;
  right: 10%;
  position: absolute;
  z-index: 20;
}

.title {
  top: 10px;
  right: 2px;
  font-size: 20px;
}

.sub {
  font-size: 10px;
}

/* .carouselimg {
  height: 875px;
} */

.carousel .carousel-item {
  height: auto;
}

.carouseltext {
  font-size: 3vw;
}
.text_container {
  height: 125vh !important;
}

@media only screen and (max-width: 768px) {
  .next {
    height: 55px;
    width: 60px;
    border-radius: 10px;
  }
  .icon1 {
    width: 25px;
  }
  .title {
    font-size: 8px;
  }

  .sub {
    font-size: 4px;
  }

  .controls {
    bottom: -7%;
    left: 13%;
  }

  .carouselimg {
    height: 350px;
  }

  .carouselvid {
    width: 350px;
    height: 30vh;
  }

  .carousel-content {
    height: 55px;
    font-size: 1vw;
    max-width: 10px;
  }

  .carousel .carousel-item {
    height: 350px;
  }
  .text_container {
    height: 350px !important;
  }
}

@media only screen and (max-width: 480px) {
  .controls {
    bottom: -7%;
    left: 10%;
  }
  .carousel .carousel-item {
    height: 650px;
  }
  .text_container {
    height: 650px !important;
  }
  .carouselimg {
    height: 220px;
  }
  /* .carousel-content {
    height: 55px;
    font-size: 2vw;
    max-width: 220px;
  } */
  .carouseltext {
    font-size: 5vw;
  }
  .carousel-content {
    height: 100px;
    font-size: 4vw;
    max-width: 220px;
  }

  .carousel-content p {
    margin-left: 5px;
    margin-top: 5px;
  }

  .next {
    height: 100px;
    width: 90px;
    border-radius: 10px;
  }
  .title {
    font-size: 4vw;
  }
  .sub {
    font-size: 2vw;
  }
}
