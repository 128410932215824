.inputbox-gst {
    border: white;
    background-color: transparent;
}
.inputbox-gst:focus{
    /* border: white; */
    outline: none;
}
.wrapper {
    text-transform: uppercase;

    color: #555;
    cursor: help;
    font-size: 15px;
    /* padding: 15px 20px; */
    position: relative;
    text-align: center;
    -webkit-transform: translateZ(0);
    /* webkit flicker fix */
    -webkit-font-smoothing: antialiased;
    /* webkit text rendering fix */
}

.wrapper .tooltip {
        text-align: center;
    border-radius: 8px;
    background: black;
    bottom: 100%;
    color: #fff;
    display: block;
    left: 0px;
    margin-bottom: 15px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid black 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
}

.wrapper:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
    display: none;
}

.lte8 .wrapper:hover .tooltip {
    display: block;
}

.loader {
    height: 4px;
    width: 130px;
    --c: no-repeat linear-gradient(black 0 0);
    background: var(--c), var(--c), #f6f7f8;
    background-size: 60% 100%;
    animation: l16 3s infinite;
}

@keyframes l16 {
    0% {
        background-position: -150% 0, -150% 0
    }

    66% {
        background-position: 250% 0, -150% 0
    }

    100% {
        background-position: 250% 0, 250% 0
    }
}