.bgblack {
    background-color: black;
}

.headingfont {
    font-size: 60px;
}

.textgrey {
    color: #929292;
}
.textlightgray{
    color: #5e5d5d;
}
.custombottom{
    bottom: 46.7%;
}
.righticon{
        display: flex;
            flex-flow: row-reverse;
}
.blog_font{
    font-size:18px;
  }
  .padding_btm{
    padding-bottom:6%
  }
@media only screen and (max-width: 640px) {
    .headingfont {
        font-size: 30px;
    }
        .custombottom {
            bottom: -5%;

        }
        .blog_font{
            font-size:16px;
          }
          .padding_btm{
            padding-bottom:12%
          }
}