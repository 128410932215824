.main-container {
  background-color: #171727;
}

.img-cont {
  background-color: #f6f7f8;
}

.textgrey{
  color: #b5b5b5;
}
.product-image {
  width: 100%;
  height: 100%;
}
.greytext{
  color: #b5b5b5;
}
.buy-from-amazon_text{
  color: black;
}
.graish_text{
  color: #4f4444;
}

.product-image-multiple {
  /* width: 100px;
  height: 63px; */
  /* margin-top: 3%; */
  width: 17%;
    height: 100%;
}
.product-image-multiple-container {
      bottom: -6%;
}
/* .product-image,
.product-image-multiple {
  max-width: 100%;
  height: auto;
} */
.buttom {
  margin-bottom: 0px;
}

.intrashieldcontainer {
  color: #101010;
}

.product-cont {
  background-color: #e3e7f2;
}

.sku-text {
  color: #616161;
}

.desc-width {
  width: 500px;
  color: #101010;
}

.rating {
  background-color: #ffcd07;
}

.delivery-charge-price {
  color: #003cff;
}

.update-price-container {
border: 2px solid black;
  /* width: 120px; */
  /* height: 42px; */
}

.plus-qty {
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  border: none;
  background-color: #e3e7f2;
}
.add-to-cart_text{
  background-color: #101010;
}
.addtocart-container {
  width: 250px;
  height: auto;
  /* margin-left: 10px; */
  padding: 10px;
  background-color: #101010;
}


.buy-from-amazon {
  
margin-top: 10px;
  cursor: pointer;
}

.four-image {
  width: 270px;
}

.eye-image {
  width: 800px;
}

.eye-text {
  padding: 60px;
  font-size: 22px;
}

.txt-color {
  color: #1f1d1b;
}
.bg-eye {
  background-color: #cdbfbc;
}
.application-image {
  width: 120px;
  /* margin-left: 22px; */
}
.application-image-2 {
  width: 120px;
  margin-left: 22px;
}
.application-text {
  padding: 92px 0px 0px 0px;
  margin-bottom: 0;
}
.color-txt {
  color: #cdbfbc;
}
.table_div {
  height: 380px;
}
.product-specifion {
  background-color: #f1e8e6;
  /* margin-top: 13.8%; */
  height: auto;
}
.product-specifion-image {
  width: 170px;
  
}
th {
  color: #cdbfbc;
}
td {
  color: #cdbfbc;
}
.world-class-text {
  color: #616161;

}


.revo-image {
  width: 100%;
  opacity: 1;
}
.revo-image-2 {
  width: 100%;
}
.certification_font {
  font-size: 28px;
}
.certificate_img img{
  border-radius: 25px;
opacity: 0.6;
}
.certificate_img img:hover{
opacity: 1;
}

.product-image-multiple-container::-webkit-scrollbar {
  width: 1px;
  display: none;            
}
.product-image-multiple-container::-webkit-scrollbar-track-piece:end {
  display: none;
}
.product-image-multiple-container::-webkit-scrollbar-track {
  background: white;
}


.product-image-multiple-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 1px solid green;
  /* background-color: red; */
  
 
}


@media only screen and (max-width: 640px) {
  .addtocart-container {
    font-size: 13px;
    width: 200px;
    /* height: 43px; */
    margin-left: 10px;
  }
  .certification_font {
    font-size: 19px;
  }
  .product-specifion {
    background-color: #f1e8e6;
    margin-top: 0%;
  
  }
  .eye-text {
   
    font-size: 17px;
  }
  .product-image {
    width: 100%;
    height: auto;
    margin-top: 6%;
   
  }
  .buy-from-amazon {
  font-size: 12px;
    margin-top: 5px;
      cursor: pointer;
    }
    .product-image-multiple-container {
      bottom: -7%;
      margin-right: 0%;
}
}
.check-certification{
      display: flex;
        flex-flow: row-reverse;
        margin-top: 11px;
        margin-right: 13px;
}

.tech-card-girl{
  border: 6px solid #0d84fa;
  border-radius: 50%;
}


.loader {
  height: 4px;
  width: 130px;
  --c: no-repeat linear-gradient(black 0 0);
  background: var(--c), var(--c), #f6f7f8;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}

@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}